section.aboutussection {
    padding: 180px 0px 80px;
    position: relative;
}

.aboutcontent h2 {
    font-size: 40px;
    color: #1A62F5;
    margin-bottom: 30px;
}

.aboutcontent p {
    font-size: 18px;
    color: #0F172A;
}

.aboutcontent {
    max-width: 815px;
    margin-bottom: 80px;
}

.ourmission {
    background: #FFFFFF;
    border: 1px solid #00000024;
    border-radius: 15px;
    padding: 38px;
}

.ourmission h3 {
    font-size: 32px;
    color: #080921;
    margin-bottom: 24px;
}

.ourmission p {
    font-size: 16px;
    color: #080921;
    letter-spacing: 0.5px;
}

section.aboutussection:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 708px;
    height: 638px;
    background-image: url(../../../public/Images/aboutbg.png);
    z-index: -9;
}