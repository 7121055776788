.title h2 {
  color: 080921;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 44px */
}
.bussinesworkdetail h3 {
  font-size: 18px;
  color: #080921;
  margin-bottom: 12px;
}

.bussinesworkdetail p {
  font-size: 16px;
  color: #8e8e93;
}

.bussinesworkdetail img {
  margin-bottom: 32px;
}
.bussineswormain .bussinesworkdetail img {
  margin-bottom: 12px;
}

.bussinesworkdetail {
  padding: 16px;
  border-radius: 12px;
  background: white;
}
@media (max-width: 767px) {
  .title h2 {
    font-size: 24px;
  }
}
