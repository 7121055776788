.findTheBestHeader div h2 {
  color: 080921;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
.findTheBestHeader .searchInput {
  display: flex;
  width: 448px;
  height: 36px;
  padding: 8px 24px;
  align-items: center;
  gap: 20px;
  background-color: #ffffff;
  border-radius: 50px;
}
.searchInput input::-webkit-search-cancel-button {
  display: none;
}
.getMoreBtn {
  height: 46px;
  background: #1a62f5;
  color: white;
  border-radius: 50px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #1a62f5;
  transition: all 0.5s;
  &:hover {
    background: #113e9d;
    color: #ffffff;
  }
}
/* infleuncer profile styles*/
.influencerdetail {
  padding: 20px;
  /* display: block; */
  background: #ffffff;
  border-radius: 19px;
  height: calc(100% - 25px);
  margin-bottom: 20px;
}
.influencerdetail i {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 11px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
}
.influencerdetail h3 {
  font-size: 15px;
  color: #080921;
  img {
    margin-left: 4px !important;
    display: inline-block;
  }
}
.influencerdetail p {
  font-size: 12px;
  color: #aeaeb2;
  word-wrap: break-word;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.influencerdetail .socialmedia {
  display: flex;
  align-items: center;
  gap: 13px;
  flex-wrap: wrap;
}
.influencerdetail .socialmedia li {
  font-size: 12px;
  color: #8e8e93;
  display: flex;
  align-items: center;
  gap: 3px;
  line-height: 14px;
}
.influencerdetail .socialmedia li span {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.influencerdetail h5 {
  font-size: 13px;
  color: #080921;
  font-weight: 500;
}
.influencerdetail h5 span {
  color: #8e8e93;
}
@media (max-width: 767px) {
  .findTheBestHeader div h2 {
    font-size: 24px;
  }
}
