section.contactus.ar {
    direction: rtl;
}

section.contactus.ar {
    direction: rtl;
}

.contactform form.mr-0 {
    margin-right: 0;
    margin-left: 50px;
}

ul.contact.ar span {
    margin-right: 0;
    margin-left: 15px;
}

.arcontactimg {
    transform: rotateY(180deg);
    display: flex;
}

li.arphonenumber {
    display: flex;
    direction: ltr;
    justify-content: flex-end;
}