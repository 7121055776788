.title h2 {
  color: 080921;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 44px */
}
.accordion {
  background-color: #fff;
  padding: 24px;
  border-radius: 24px;
}
.accordionItem {
  border: 0;
  border-bottom: 1px solid #f2f2f7;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.accordionItem:last-of-type {
  border: 0;
}
.accordionHeader button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    color: #080921;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 38.4px */
  }
  &::after {
    display: none;
  }
  &:not(.collapsed) {
    background: transparent;
    border: none;
    color: #080921;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.accordionBody {
  color: #8e8e93;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}
:dir(rtl) {
  .accordionHeader button h3 {
    text-align: start;
  }
}
@media (max-width: 767px) {
  .title h2 {
    font-size: 24px;
  }
  .accordionItem {
    padding: 0;
    gap: 8px;
  }
  .accordionHeader button {
    gap: 8px;
    h3 {
      width: 90%;
      font-size: 16px;
    }
  }
  .accordionBody {
    font-size: 14px;
  }
}
