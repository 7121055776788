.header {
  min-height: 768px;
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  &:dir(ltr) {
    &::after {
      right: 0;
    }
  }
  &:dir(rtl) {
    &::after {
      left: -5%;
    }
  }
  &::after {
    content: "";
    position: absolute;
    transform: rotate(-90deg);
    top: -80px;
    width: 600px;
    height: 760px;
    background-image: url("../../../../public/Images/herovector.png");
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }
}
.head1 {
  font-size: 56px;
  color: #080921;
  font-weight: bolder;
  line-height: 110%;
  span {
    font-size: 56px;
    color: #1a62f5;
    font-weight: bolder;
    line-height: 110%;
  }
}
.head5 {
  color: #0f172a;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 26.4px */
}
.text {
  color: #0f172a;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  text-transform: capitalize;
}
.btn {
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
  letter-spacing: 0.5px;
  display: flex;
  width: 264px;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 46px;
  &:hover {
    color: #fff !important;
    background: #1a62f5 !important;
    transition: all 0.5s;
  }
}
.btn1 {
  color: #fff;
  border: 2px solid #1a62f5;
  background: #1a62f5;
}

.btn2 {
  color: #1a62f5;
  border: 2px solid #1a62f5;
}
.heroImg {
  img {
    max-width: 698px;
    height: auto;
    object-fit: cover;
  }
}
@media (max-width: 767px) {
  .head1 {
    font-size: 40px;
    span {
      font-size: 40px;
    }
  }
}
