section.privacy-policy {
    padding-top: 220px;
}
.privacy-policy-title h2 {
    text-align: center;
    font-size: 32px;
    color: #1A62F5;
    font-weight: 600;
    position: relative;
}
.privacy-policy-title h2::after {
    background-image: url(../../../public/Images/star.svg);
    content: "";
    position: absolute;
    width: 221px;
    height: 214px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.privacy-bg {
    margin-top: 120px;
    background-color: #fff;
    padding: 40px;
    border-radius: 24px;
}
.conditions {
    margin-bottom: 40px;
}

.privacy-bg p {
    font-size: 16px;
    color: #080921;
}

.privacy-bg h3 {
    font-size: 30px;
    color: #000;
    font-weight: 500;
    margin-bottom: 18px;
}