.nav {
  height: 96px;
  max-height: 100px;
}
.navItem {
  color: #080921;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
  &:hover {
    transition: all 0.5s;
    /* color: #1b62f5; */
    color: #1a62f5;
  }
  img {
    filter: invert(0.9);
  }
}
.btn {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
  letter-spacing: 0.5px;
  display: flex;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 46px;
  color: #fff;
  border: 2px solid #1a62f5;
  background: #1a62f5;
  &:hover {
    color: #fff !important;
    background: #1a62f5 !important;
    transition: all 0.5s;
  }
}
