section.findbestsec.inflancerlist {
    padding: 160px 0px 100px;
}

.pagination button {
    background: transparent;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 1px solid #080921;
    opacity: 1;
    padding: 2px !important;
}

.pagination {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
}

.navbar-toggler {
    order: -1;
    padding: 0;
    border: navajowhite;
    position: relative;
    z-index: 1;
    width: 30px;
    height: 30px;
    transition: all 0.5s;
    box-shadow: none !important;
}

.navbar-toggler-icon {
    width: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background: #000;
    height: 1px;
    transition: all 0.5s;
}

.navbar-toggler-icon::after {
    content: "";
    position: absolute;
    top: -8px;
    width: 100%;
    height: 1px;
    background: #000;
    left: 0;
    transition: all 0.5s;
}

.navbar-toggler-icon::before {
    content: "";
    position: absolute;
    bottom: -8px;
    width: 100%;
    height: 1px;
    background: #000;
    left: 0;
    transition: all 0.5s;
}


.fade.pricemodal.filtermdal .modal-header {
    position: relative;
    border: none;
    right: 0px;
    z-index: 99999;
}



.selectfiltermain button img {
    width: 20px;
    margin-left: 2px;
}

.fade.pricemodal.filtermdal.pricemodal.filtermdal.filtermain.modal ul li {
    font-family: 'Roboto';
}

.selectoption.d-flex .css-b62m3t-container {
    max-width: 100%;
}

.selectoption.d-flex {
    gap: 20px;
}
ul.pricemain.starslocation {
    height: 269px;
    overflow: auto;
}

ul.pricemain.starslocation {}

ul.pricemain.starslocation::-webkit-scrollbar {
    display: none;
}
.search-header input[type="radio"] {
    margin-left: auto;
    width: 23px;
    height: 23px;
}

.search-header label {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

/* .search-header ul {
    width: 50%;
} */

.searchicon {
    position: relative;
}
.filteraccord.accordion button {
    padding: 10px 0 0 !important;
}
.fade.pricemodal.filtermdal.pricemodal.filtermdal.filtermain.modal .accordion-body {
    padding: 0 0 0px;
}

ul.pricemain {
    margin-top: 25px;
}

.filteraccord.accordion button button {
    padding: 0 !important;
}

.filteraccord.accordion button {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}
.selecteddata {
    display: inline-block;
    border: 1px solid lightgray;
    width: auto;
    padding: 6px;
    border-radius: 10px;
    margin-bottom: 21px;
    color: black;
}
.accordion-body .search-header {
    margin-top: 20px;
}

ul.pricemain.starslocation {
    margin-top: 0;
}
.selectedcity.d-flex .selectoption.d-flex {
    width: 50%;
}

.selectedcity.d-flex .selectoption.d-flex + ul.pricemain.starslocation {
    width: 50%;
}

.selectedcity.d-flex {
    gap: 30px;
}

button.cancelbtn-outside.btn {
    text-decoration: underline;
    font-weight: bold;
}

span.count-filter {
    background: black;
    color: #FFFFFF;
    padding: 4px 8px;
    size: 14px;
    line-height: 20px;
    align-items: center;
    font-weight: 500;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}