.downloadnojom {
  padding: 40px 74px;
  background-color: #eef6ff;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 32px;
  position: relative;
}
.title h3 {
  color: #000;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 61.6px */
  text-transform: capitalize;
}
.forBrand {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.subTitle h4 {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 110%; /* 35.2px */
}
.downloadBtns {
  display: flex;
  align-items: center;
  gap: 16px;
  a {
    display: block;
    height: 40px;
    width: 135px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
.appModal {
  position: absolute;
  right: 60px;
  left: unset;
  top: 50;
  top: 50%;
  transform: translateY(-50%);
}
:dir(rtl) {
  .appModal {
    right: unset;
    left: 30px;
  }
  .downloadBtns {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
@media (max-width: 767px) {
  .downloadnojom {
    padding: 48px 16px;
    border-radius: 0;
    gap: 28px;
  }
  .title h3 {
    text-align: center;
    font-size: 32px;
  }
  .subTitle h4 {
    text-align: center;
    font-size: 24px;
  }
  .downloadBtns {
    justify-content: center !important;
  }
  .appModal {
    display: none;
  }
}
