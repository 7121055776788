@font-face {
    font-family: 'Noto Sans Arabic Condensed';
    src: url('NotoSansArabicCondensed-Black.woff2') format('woff2'),
        url('NotoSansArabicCondensed-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic Condensed';
    src: url('NotoSansArabicCondensed-ExtraBold.woff2') format('woff2'),
        url('NotoSansArabicCondensed-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic Condensed';
    src: url('NotoSansArabicCondensed-Bold.woff2') format('woff2'),
        url('NotoSansArabicCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic ExtraCondensed';
    src: url('NotoSansArabicExtraCondensed-ExtraBold.woff2') format('woff2'),
        url('NotoSansArabicExtraCondensed-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic Condensed';
    src: url('NotoSansArabicCondensed-ExtraLight.woff2') format('woff2'),
        url('NotoSansArabicCondensed-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic ExtraCondensed';
    src: url('NotoSansArabicExtraCondensed-Bold.woff2') format('woff2'),
        url('NotoSansArabicExtraCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic Condensed';
    src: url('NotoSansArabicCondensed-Light.woff2') format('woff2'),
        url('NotoSansArabicCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic ExtraCondensed';
    src: url('NotoSansArabicExtraCondensed-Black.woff2') format('woff2'),
        url('NotoSansArabicExtraCondensed-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic Condensed';
    src: url('NotoSansArabicCondensed-Thin.woff2') format('woff2'),
        url('NotoSansArabicCondensed-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic Condensed';
    src: url('NotoSansArabicCondensed-SemiBold.woff2') format('woff2'),
        url('NotoSansArabicCondensed-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic Condensed';
    src: url('NotoSansArabicCondensed-Regular.woff2') format('woff2'),
        url('NotoSansArabicCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic Condensed';
    src: url('NotoSansArabicCondensed-Medium.woff2') format('woff2'),
        url('NotoSansArabicCondensed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic ExtraCondensed';
    src: url('NotoSansArabicExtraCondensed-ExtraLight.woff2') format('woff2'),
        url('NotoSansArabicExtraCondensed-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic ExtraCondensed';
    src: url('NotoSansArabicExtraCondensed-Medium.woff2') format('woff2'),
        url('NotoSansArabicExtraCondensed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans Arabic ExtraCondensed';
    src: url('NotoSansArabicExtraCondensed-Light.woff2') format('woff2'),
        url('NotoSansArabicExtraCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

