/* footer {
  background: #080921;
  padding: 64px;
} */

.footermail h4 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 20px;
}

.footermail p {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
}

.mail input {
  height: 48px;
  width: 100%;
  background: transparent;
  border: 1px solid #8E8E93;
  border-radius: 50px;
  padding: 0 20px;
  max-width: 547px;
}

.mail form {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mail .btn-blue {
  width: 127px;
  height: 48px;
  border-radius: 50px;
  background: #1A62F5;
  border: 1px solid #1A62F5;
  color: white;
  font-size: 2;
}

.mail+p {
  color: gray;
  font-size: 16px;
  margin-top: 10px;
}

.mail+p a {
  color: white;
}

.footerbtns {}

.languges {
  border: 2px solid gray;
  width: 77px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 50px;
  color: white;
}

.languges img {
  margin: 0;
  margin-right: 4px;
}

.footerbtns {
  margin-bottom: 48px;
}

.jointalent h6 {
  font-size: 20px;
}

a.btn-talent {
  border: 2px solid gray;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 50px;
  width: auto;
  color: white;
  padding: 0 41px;
  font-size: 16px;
}

.jointalent {
  margin-bottom: 24px;
}

/* footer {
  background: #080921;
  padding: 64px;
} */

.footermail h4 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 20px;
}

.footermail p {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
}

.mail input {
  height: 48px;
  width: 100%;
  background: transparent;
  border: 1px solid #8E8E93;
  border-radius: 50px;
  padding: 0 20px;
  max-width: 547px;
}

.mail form {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mail .btn-blue {
  width: 127px;
  height: 48px;
  border-radius: 50px;
  background: #1A62F5;
  border: 1px solid #1A62F5;
  color: white;
  font-size: 2;
}

.mail+p {
  color: gray;
  font-size: 16px;
  margin-top: 10px;
}

.mail+p a {
  color: white;
}

.footerbtns {}

.languges {
  border: 2px solid gray;
  width: 77px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 50px;
  color: white;
}

.languges img {
  margin: 0;
  margin-right: 4px;
}

.footerbtns {
  margin-bottom: 48px;
}

.jointalent h6 {
  font-size: 20px;
}

a.btn-talent {
  border: 2px solid gray;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 50px;
  width: auto;
  color: white;
  padding: 0 41px;
  font-size: 16px;
}

.jointalent {
  margin-bottom: 24px;
}

ul.footermenu {
  display: flex;
  align-items: center;
  gap: 50px;
}

ul.footermenu:after {
  display: none;
}

ul.footermenu a {
  font-size: 16px;
  color: white;
}

ul.footersocial {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 19px;
}

.copyright p {
  font-size: 16px;
  color: gray;
}

.copyright {
  margin-top: 40px;
}

section.downloadnojomsec {
  padding-bottom: 150px;
}
.d-none{
  display: none;
}
.mail input::placeholder {
  color: #8E8E93;
}

ul.footersocial a {
  display: flex;
  align-items: center;
  height: 24px;
}

ul.footersocial a img {
  width: 100%;
  height: 100%;
}