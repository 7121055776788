.findmoreinfluence h2 {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.subTitle h4 {
  color: #1a62f5;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.bussinesworkdetail {
  display: flex;
  padding: 40px;
  flex-direction: column;
  gap: 40px;
  border-radius: 24px;
  background: #fff;
}
.list li {
  color: #080921;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  text-transform: capitalize;
}
a.applynowbtn {
  height: 46px;
  background: #1a62f5;
  color: white;
  border-radius: 50px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #1a62f5;
  transition: all 0.5s;
  &:hover {
    background: #113e9d;
    color: #ffffff;
  }
}
@media (max-width: 767px) {
  .findmoreinfluence h2 {
    font-size: 24px;
  }
}
