.css-13cymwt-control,
.css-t3ipsp-control {
  border-radius: 4rem !important;
  border: 1px solid var(--systemgray-gray6, #f2f2f7) !important;
  background: #fff;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.english body {
  font-family: "Roboto" !important;
}
header.ar.profileheader {
  text-align: right !important;
}
header.ar.profileheader
  .me-auto.ar-menu.text-end.navbar-nav.navbar-nav-scroll
  a {
  margin-right: 0 !important;
  text-align: right !important;
  justify-content: flex-end !important;
  width: 100%;
  margin: 0 !important;
}
header.a.profileheader button.btn-blue.m-lg-auto.btn.btn-primary {
  margin: 0 !important;
}
header.ar.profileheader .me-auto.ar-menu.text-end.navbar-nav.navbar-nav-scroll {
  text-align: right !important;
  display: flex;
  flex-direction: column-reverse;
}
.profileheader .d-flex.armenu {
  flex-direction: column-reverse;
}
.profileheader .d-flex.armenu button {
  margin-right: 0 !important;
  text-align: right;
}
header.ar.profileheader .me-auto.ar-menu.text-end.navbar-nav.navbar-nav-scroll {
  overflow: hidden;
}
.selectOverFlow .selectfiltermain.mr-0 {
  justify-content: flex-start !important;
}
/* new css */
.selectOverFlow button.btn.btn-primary {
  font-size: 14px;
  font-weight: 500;
}
.findmoreinfluence h2 {
  font-weight: 700;
}
.bussinesworkdetail h3 {
  font-weight: 500;
}
.bussinesworkdetail p {
  font-weight: 400;
}
.accordion-button {
  font-weight: 400px;
}

.search-header.ml-0.ml-0.searchar input,
.search-header.ml-0 input {
  direction: rtl;
  padding: 10px 15px;
  padding-right: 32px !important;
  text-align: right;
}
.search-header.ml-0 input {
}
.filteraccord.ar.accordion button {
  direction: rtl;
}
.modal-header .btn-close {
  opacity: 1;
}
.fade.pricemodal.modal .modal-body {
  padding: 0;
}
.fade.pricemodal.filtermdal .modal-header {
  padding: 0;
}
.fade.pricemodal.filtermdal.modal .modal-content {
  padding: 32px 40px;
  border-radius: 24px;
}
ul.pricemain:after {
  display: none;
}
ul.pricemain {
  margin-bottom: 32px;
}
button.btnblue.viewresult {
  font-size: 16px;
  letter-spacing: 0.5px;
}
button.btnclear.viewresult.text-english {
  color: #1a62f5;
}
.price button {
  font-weight: 600;
  font-size: 16px;
}
/* .search-header input {
  background: #f3f4f8 !important;
} */
.search-header.ar {
  direction: rtl;
}
.search-header.ar svg {
  left: auto;
  right: 15px;
}
.search-header input {
  padding-right: 38px;
}
.search-header.ml-0.ml-0.searchar input {
  background: white !important;
}
.search-header.ml-0.ml-0.searchar input {
  background: white !important;
}
ul.pricemain {
  height: calc(100vh - 600px);
  overflow: auto;
}
ul.pricemain::-webkit-scrollbar {
  display: none;
}
.price {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 12px;
}
ul.pricemain {
  margin-bottom: auto;
}
.fade.pricemodal.filtermdal.ar.modal h4 {
  text-align: right;
  margin-bottom: 0px;
}
.fade.pricemodal.filtermdal.ar.modal button.btn-close {
  margin-left: 0;
}
ul.pricemain.pricecss {
  height: auto;
  margin-bottom: 15px;
}
.filteraccord.accordion {
  height: calc(100vh - 450px);
  overflow: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}
.filteraccord.accordion::-webkit-scrollbar {
  display: none;
}
.filteraccord.ar.accordion .accordion-item {
  text-align: right;
}
footer.ar .mail + p {
  display: flex;
  justify-content: flex-end;
}
.profile-tag-info.ar h2 {
  display: flex;
  flex-direction: row-reverse;
}
input:focus::placeholder {
  color: transparent;
}
.ourmission {
  height: 100%;
}
/* nav.bg-body-tertiary.englishheader.navbar.navbar-expand-lg.navbar-light
  button.btn-blue.btn.btn-primary {
  margin-left: auto;
} */
.ar .search-header {
  direction: rtl;
  border-radius: 50px;
  overflow: hidden;
}
.ar .search-header svg {
  left: auto;
  right: 20px;
}
/* nav.bg-body-tertiary.englishheader.navbar.navbar-expand-lg.navbar-light
  button.btn-blue.btn.btn-primary {
  margin-left: auto;
} */
.ar .search-header {
  direction: rtl;
  border-radius: 50px;
  overflow: hidden;
}
.ar .search-header svg {
  left: auto;
  right: 15px;
}
.price.checkcategories.ar {
  text-align: right;
}
.price.checkcategories.ar .search-header.ar {
  margin-bottom: 10px;
  margin-top: 13px;
}
.price.checkcategories.ar h4 {
  margin-bottom: 20px !important;
}
.price.checkcategories.ar ul.pricemain.ar {
  margin-bottom: 29px;
  height: 40vh;
}
.price.checkcategories.ar .search-header.ar input {
  margin: 0 !important;
}
.selectfiltermain button.btn.btn-primary.selectedfilter {
  border: 1px solid #bcdcff !important;
  background: #eef6ff !important;
}
.me-auto.navbar-nav.navbar-nav-scroll {
  gap: 24px;
}
.faqsec .accordion {
  padding: 24px;
  background: white;
  border-radius: 30px;
}
/* .faqsec .accordion .accordion-item {
  border-bottom: none;
} */
@media only screen and (max-width: 1429px) {
  .container {
    max-width: 1060px !important;
  }
  .search-header input {
    width: 237px;
  }
  .me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
    gap: 10px;
  }
  button.btn.btn-btn-blue {
    width: 140px;
  }
  .downloadnojom h3 {
    font-size: 40px;
  }
  .forbrand h4 {
    font-size: 24px;
  }
  ul.footermenu {
    gap: 25px;
  }
  .jointalent h6 {
    font-size: 17px;
  }
  .footermail p {
    font-size: 16px;
  }
  .findmoreinfluence h2 {
    font-size: 32px;
    margin-bottom: 0;
  }
  .findmoreinfluence {
    margin-bottom: 30px;
  }
  ul.forbrand li {
    font-size: 17px;
  }
  .bussinesworkdetail.p-5 {
    padding: 30px !important;
  }
  .bussinesworkdetail h4 {
    font-size: 30px;
    margin-bottom: 28px;
  }

  section {
    padding: 40px 0px;
  }
  .nojom h3 {
    font-size: 16px;
  }
  span.appmodal img {
    max-width: 420px;
  }
}
@media only screen and (max-width: 1199px) {
  .heroimg {
    right: 0;
    left: 0 !important;
    position: relative;
    transform: inherit;
  }
  .me-auto.navbar-nav.navbar-nav-scroll {
    padding: 0;
    margin-bottom: 5px;
    gap: 0;
  }
  .me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll a {
    font-size: 14px;
  }
  button.btn.btn-btn-blue {
    font-size: 15px;
    width: 125px;
  }
  .search-header input {
    margin-right: 0 !important;
    font-size: 15px;
  }
  .container {
    max-width: 930px !important;
  }
  .me-auto.my-2.my-lg-0.navbar-nav.navbar-nav-scroll {
    gap: 2px;
  }
  .heroinfo h1 {
    font-size: 45px;
    margin-bottom: 30px;
  }
  .heroinfo h1 span {
    font-size: 45px;
  }
  .heroinfo h5 {
    font-size: 20px;
  }
  .heroinfo p {
    font-size: 16px;
  }
  .herobtn a {
    max-width: 160px;
    font-size: 20px;
    height: 55px;
  }
  @media (max-width: 767px) {
    .herobtn a {
      max-width: 100%;
    }
  }
  .herobtn {
    gap: 18px;
  }
  section.herobanner:after {
    width: 420px;
    height: 530px;
    z-index: -9;
  }
  section.herobanner {
    height: 660px;
  }
  section.findbestsec {
    padding-top: 0;
  }
  .categorieslist.viewall {
    width: 98px;
    height: 98px;
  }
  .downloadnojom {
    padding: 40px;
  }
  footer {
    padding: 40px;
  }
  ul.footermenu {
    gap: 16px;
  }
}
@media (max-width: 991px) {
  header.ar .me-auto.ar-menu.text-end.navbar-nav.navbar-nav-scroll {
    margin-right: 0 !important;
    gap: 6px;
  }
  .ar + section .selectfilter {
    align-items: flex-end;
  }
  .search-header {
    order: -1;
  }
  .selectfilter {
    flex-direction: column;
    align-items: flex-start;
  }
  .search-header {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .findmoreinfluence.text-end + .selectfilter {
    flex-direction: column;
    align-items: flex-end;
  }
  .search-header.ml-0.ml-0.searchar {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  .profileheader .navbar-collapse {
    margin: 0 !important;
  }
  section.whynojomsection .row {
    gap: 30px !important;
  }
  .startcampaigncontent h2 {
    font-size: 32px !important;
    line-height: 40px !important;
  }
  .startcampaigncontent p {
    font-size: 18px !important;
  }
  .startcampaigncontent a {
    max-width: 300px !important;
    height: 60px !important;
    font-size: 20px !important;
  }
  .ourmission h3 {
    font-size: 26px !important;
  }
  footer.profilefooter ul {
    flex-direction: column;
    gap: 16px;
  }
  footer.profilefooter ul li:first-child {
    order: 1;
  }
  .downloadbtn.justify-content-end {
    justify-content: center !important;
  }
  .heroimg {
    display: none;
  }
  section.herobanner:after {
    display: none;
  }
  section.herobanner {
    height: auto;
    min-height: 640px;
    padding-bottom: 0;
  }
  section.herobanner .col {
    width: 100%;
    flex: auto;
  }
  span.appmodal {
    right: 0;
    z-index: -1;
  }
  .downloadnojom {
    z-index: 2;
  }
  .downloadnojom h3 {
    max-width: 348px;
  }
  ul.footermenu {
    flex-wrap: wrap;
  }
  ul.footersocial a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  .navbar-toggler {
    order: -1;
    padding: 0;
    border: navajowhite;
    position: relative;
    z-index: 1;
    width: 30px;
    height: 30px;
    transition: all 0.5s;
    box-shadow: none !important;
  }
  .navbar-toggler-icon {
    width: 18px;
    position: absolute;
    border-radius: 4rem;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background: #000;
    height: 2px;
    transition: all 0.5s;
  }
  .navbar-toggler-icon::after {
    content: "";
    position: absolute;
    top: -6px;
    width: 100%;
    height: 2px;
    background: #000;
    border-radius: 4rem;
    left: 0;
    transition: all 0.5s;
  }
  .navbar-toggler-icon::before {
    content: "";
    position: absolute;
    border-radius: 4rem;
    bottom: -6px;
    width: 100%;
    height: 2px;
    background: #000;
    left: 0;
    transition: all 0.5s;
  }
  .navbar-brand {
    margin: 0 auto;
    /* padding-right: 30px; */
  }
  .navbar-collapse {
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 94px 0px rgba(0, 0, 0, 0.1);
  }
  .navbar-nav-scroll {
    padding: 12px 0px 0;
    margin-bottom: 10px;
  }
  .me-auto.navbar-nav.navbar-nav-scroll {
    max-height: 300px !important;
  }
  button.btn-blue.btn.btn-primary {
    color: #080921;
    font-size: 16px;
    font-weight: bold;
    margin: 0px 23px 0px 15px;
    background: transparent;
    height: auto;
    border: navajowhite;
    padding: 8px 0 18px 0;
  }
  .sidebar-show .navbar-toggler-icon {
    background: transparent;
  }
  .sidebar-show .navbar-toggler-icon:after {
    transform: rotate(45deg);
    top: 0;
    transition: all 0.5s;
  }
  .sidebar-show .navbar-toggler-icon:before {
    transform: rotate(-45deg);
    bottom: 0;
    transition: all 0.5s;
  }
  /* a.navbar-brand {
    position: absolute;
    top: 13px;
    padding-right: 0;
    left: 50%;
    transform: translateX(-50%);
  } */
  div#navbarScroll button {
    background: transparent !important;
    padding: 0;
    height: auto !important;
    color: black !important;
  }
  div#navbarScroll {
    padding: 15px 10px;
    margin-top: 13px;
  }
  header.ar {
    text-align: right !important;
  }
  header.ar .me-auto.ar-menu.text-end.navbar-nav.navbar-nav-scroll a {
    margin-right: 0 !important;
    text-align: right !important;
    justify-content: flex-end !important;
    width: 100%;
    margin: 0 !important;
  }
  header.ar button.btn-blue.m-lg-auto.btn.btn-primary {
    margin: 0 !important;
  }
  header.ar .me-auto.ar-menu.text-end.navbar-nav.navbar-nav-scroll {
    text-align: right !important;
    display: flex;
    flex-direction: column-reverse;
  }
  .d-flex.armenu {
    flex-direction: column-reverse;
  }
  .d-flex.armenu button {
    margin-right: 0 !important;
    text-align: right;
  }
  header.ar .me-auto.ar-menu.text-end.navbar-nav.navbar-nav-scroll {
    overflow: hidden;
  }
}
@media (max-width: 767px) {
  .selectfiltermain button.btn.btn-primary {
    padding-right: 25px;
  }
  .faqsec .accordion {
    padding: 0px;
    overflow: hidden;
    border-radius: 20px;
  }
  footer.ar .col-lg-4.col-md-5 {
    order: 1;
  }
  .footerbtns {
  }
  footer.ar button {
    margin-left: auto;
  }
  footer.ar .jointalent {
    text-align: right;
    margin-top: 10px;
  }
  footer.ar form.justify-content-end {
    flex-direction: column-reverse;
  }
  footer.ar form.justify-content-end {
    display: flex;
    flex-wrap: nowrap !important;
    flex-direction: row;
  }
  footer.ar form.justify-content-end .btn-blue {
    width: 140px;
  }
  footer.ar form.justify-content-end input {
    text-align: right;
  }
  section.contactus {
    padding-top: 100px;
  }
  .contactform {
    padding: 20px;
  }
  .contactdetail span {
    display: none;
  }
  ul.contact a {
    font-size: 18px;
  }
  .contactform form {
    margin: 0 !important;
  }
  .contactformtitle p {
    font-size: 14px;
  }
  .contactformtitle h3 {
    font-size: 32px;
  }
  label.form-label {
    font-size: 14px;
  }
  section.privacy-policy {
    padding-top: 170px;
  }
  .privacy-bg h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .privacy-bg p {
    font-size: 15px;
  }
  .findmoreinfluence.justify-content-end + .bussineswormain {
    direction: rtl !important;
  }
  .profilesocialmediamain ul li a {
    width: 32px;
    height: 32px;
  }
  .profilesocialmediamain ul {
    padding: 32px;
    gap: 20px;
  }
  ul.forbrand img {
    width: 23px;
    height: 23px;
  }
  ul.forbrand li {
    font-size: 16px;
  }
  .bussinesworkdetail img {
    max-width: 80px;
  }
  .downloadmodal h3 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .downloadmodal h3 span {
    font-size: 28px;
  }
  .downloadmodal p {
    font-size: 16px;
    line-height: 33px;
    margin-bottom: 20px;
  }
  .downloadbtn.gap-4.d-flex.justify-content-center {
    margin-bottom: 20px;
    flex-direction: column;
  }
  .downloadmodal span {
    font-size: 16px;
  }
  .modal-body {
    padding: 30px;
  }
  .downloadbtn.gap-4.d-flex.justify-content-center img {
    max-width: 192px;
  }
  .heroinfo h1 {
    color: var(--blog-text, #080921);
    font-family: "Noto Sans Arabic Condensed";
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
    /* 44px */
    margin-bottom: 25px;
  }
  .heroinfo h5 {
    display: none;
  }
  .heroinfo p {
    color: var(--Blue-Gray-900, #0f172a);
    /* Body/L */
    font-family: "Noto Sans Arabic Condensed";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 28.8px */
    margin-bottom: 28px;
  }
  .herobtn a {
    font-family: "Noto Sans Arabic Condensed";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.5px;
  }
  section.herobanner {
    min-height: 550px;
  }
  .selectfilter {
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
  .findmoreinfluence h2 {
    color: var(--blog-text, #080921);
    font-family: "Noto Sans Arabic Condensed";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 26.4px */
    margin-bottom: 40px;
  }
  .selectfilter .search-header {
    order: -1;
    width: 100%;
  }
  .selectfilter .search-header input {
    width: 100%;
  }
  .bussineswormain .row {
    flex-wrap: nowrap;
  }
  .bussineswormain {
    overflow: auto;
  }
  .bussineswormain .row .col-lg-4 {
    max-width: 275px;
  }
  .findmoreinfluence h2 {
    color: var(--blog-text, #080921);
    font-family: "Noto Sans Arabic Condensed";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 26.4px */
  }
  .bussinesworkdetail.p-5 {
    margin-bottom: 20px;
  }
  .profile-tag-info h2 {
    color: #000;
    font-family: "Noto Sans Arabic Condensed";
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .nojom {
    margin-bottom: 15px;
  }
  section.nojomsec {
    padding-bottom: 0;
  }
  span.appmodal {
    display: none;
  }
  section.downloadnojomsec {
    padding: 40px 0;
    text-align: center;
  }
  .downloadnojom h3 {
    margin: 0 auto 20px;
    color: #080921;
    text-align: center;
    font-family: "Noto Sans Arabic Condensed";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 35.2px */
    text-transform: capitalize;
  }
  .forbrand {
    text-align: center;
  }
  .downloadbtn {
    justify-content: center;
  }
  footer {
    padding: 40px 15px;
  }
  .text-end.col-lg-4.col-md-5 {
    display: flex;
    flex-wrap: wrap;
  }
  .footerbtns {
    width: 100%;
    margin: 0;
    order: 1;
  }
  .languges {
    margin: 20px 0 0 0;
  }
  .jointalent h6 {
    color: rgba(255, 255, 255, 0.95);
    font-family: "Noto Sans Arabic Condensed";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 32px */
    margin-bottom: 12px;
  }
  .jointalent {
    width: 100%;
    text-align: left;
  }
  .mail + p {
    color: var(--systemgray-gray2, var(--gray2, #aeaeb2));
    font-family: "Noto Sans Arabic Condensed";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 19.2px */
  }
  .mail form {
    flex-wrap: wrap;
  }
  .mail .btn-blue {
    width: 100%;
  }
  .copyright {
    text-align: center;
  }
  /* .d-none {
    display: block !important;
  } */
  .m-none {
    display: none;
  }
  ul.footersocial {
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .mt-5.d-none.row {
    margin-top: 25px !important;
  }
  ul.forbrand li {
    text-align: left;
  }
  /* .profile-tag-btn-wrap {
        flex-wrap: wrap;
    } */
  .profile-tag-btn-wrap a {
    width: 100%;
    justify-content: center;
  }
}
header.profileheader {
  top: 20px;
}
.d-flex.armenu {
  width: 100%;
}
a.influencedetail:hover {
  box-shadow: 0 0 24px rgb(0 0 0 / 8%);
}
@media (max-width: 575px) {
  /* .selectOverFlow .selectfiltermain.mr-0 {
        justify-content: flex-end !important;
    } */
  section.findbestsec .row .col-lg-3 {
    max-width: 100%;
  }
  footer.ar .mail + p {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
  }
  footer.ar form.justify-content-end {
    flex-direction: column-reverse !important;
  }
  footer.ar form.justify-content-end button {
    width: 100% !important;
  }
  footer.ar .col-lg-4.col-md-5 {
    display: flex;
    flex-direction: column;
  }
  footer.ar .jointalent {
    margin-bottom: 10px;
  }
  .price h4 {
    margin-bottom: 20px;
  }
  .fade.pricemodal.filtermdal.ar.modal .search-header {
    margin-top: 15px;
  }
  .price.checkcategories h4 {
    order: -1;
  }
  .price.checkcategories h4 {
    order: -1;
  }
  .fade.pricemodal.filtermdal.modal .modal-content {
    padding: 20px;
  }
  .fade.pricemodal.filtermdal.ar.modal .search-header {
    margin-top: 15px;
  }
  /* .filtermdal .modal-dialog.modal-dialog-centered {
    height: 70%;
  } */
  .selectfiltermain.mr-0 {
    overflow: auto !important;
  }
  .search-header.ml-0.ml-0.searchar {
    margin-left: 0 !important;
  }
  .selectOverFlow {
    width: 100%;
  }
  .selectfiltermain.mr-0 button.btn.btn-primary {
    width: auto;
    min-width: auto;
    padding: 10px !important;
    padding-left: 30px !important;
  }
  .selectfiltermain button img {
    width: 20px;
    margin-left: 2px;
  }
  .selectfiltermain button.btn.btn-primary {
    flex-wrap: nowrap;
    display: flex;
    white-space: nowrap;
    padding: 10px 30px !important;
    padding-left: 13px !important;
    align-items: center;
    gap: 6px;
  }
  .selectfiltermain::-webkit-scrollbar {
    display: none;
  }
  .fade.pricemodal.filtermdal.modal.filtermain .modal-dialog {
    height: 76%;
    /* overflow: auto; */
  }
  ul.pricemain.ar.starslocation input {
    margin-right: auto !important;
    margin-left: 0;
  }
  .selectfiltermain {
    overflow: auto;
  }
  /* a.navbar-brand {
    top: -6px;
  } */
  nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
    padding: 20px 0;
  }
  nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light .container {
    position: relative;
  }
  .me-auto.ar-menu.text-end.navbar-nav.navbar-nav-scroll {
    align-items: flex-end;
  }
  a.languges.m-lg-0.langugesheager.nav-link {
    margin-right: 10px;
    margin-top: 0 !important;
    padding: 10px 0px 6px !important;
  }
  header.profileheader nav {
    padding: 20px;
  }
  header.profileheader nav .container {
    position: relative;
  }
  a.languges.m-lg-0.langugesheager.nav-link {
    justify-content: flex-start;
  }
  a.shareicon {
    top: -9px;
  }
  section.aboutussection {
    padding-top: 110px;
  }
  .aboutcontent h2 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .aboutcontent {
    margin-bottom: 40px;
  }
  section.aboutussection .row {
    gap: 20px;
    flex-direction: column;
  }
  .privacy-bg {
    padding: 20px;
    margin-top: 80px;
  }
  .privacy-bg h3 {
    font-size: 22px;
  }
  .ourmission {
    padding: 28px;
  }
  .ourmission h3 {
    font-size: 22px !important;
    margin-bottom: 12px;
  }
  .ourmission.nojomtitle h3 br {
    display: none;
  }
  .startcampaigncontent h2 {
    font-size: 25px !important;
    line-height: 40px !important;
  }
  .fade.pricemodal .modal-content {
    background: white;
    border-radius: 20px 20px 0px 0px !important;
  }
  .pricemodal.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, 50px);
  }
  .fade.pricemodal .modal-dialog {
    position: fixed !important;
    bottom: 0 !important;
    left: 0% !important;
    right: 0% !important;
    margin-bottom: 0 !important;
    margin: 0 !important;
    display: unset !important;
    min-height: unset !important;
  }
  .fade.pricemodal.modal.show .modal-dialog {
    transform: none;
  }
  .fade.pricemodal .modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: auto;
    height: 100%;
  }
  button.btn-close:focus {
    outline: none;
    box-shadow: none;
  }
  /* profile */
  .profilesocialmediamain ul {
    background: transparent !important;
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    gap: 20px;
  }
  .profilesocialmediamain {
    background: transparent;
    border: none;
  }
  .profilesocialmediamain ul li {
    width: 100%;
    background: white;
    border-radius: 10px;
    padding: 8px 13px;
    display: flex;
    align-items: center;
  }
  .profilesocialmediamain i {
    height: 24px;
    line-height: 0;
  }
  .profile-tag-btn-wrap a {
    height: 40px;
  }
  a.btn.btn-gray {
    border-color: #ededee !important;
    background: #ededee !important;
  }
  .profile-tag-info h2 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.13px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profilesocialmediamain img {
    filter: brightness(0.1);
  }
  .profilesocialmediamain a {
    width: 100%;
  }
  .profilesocialmediamain ul li a {
    width: 100%;
    align-items: center;
  }
  .profilesocialmediamain span {
    color: #33333b;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-align: left;
  }
  .profilesocialmediamain span {
    display: flex;
    align-items: center;
    gap: 9px;
    /* margin-right: 9px; */
  }
  a.btn.btn-gray.btn-profile-build {
    width: 100%;
    background: #ededee !important;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    text-align: left;
    text-align: center !important;
  }
  a.btn.btn-gray.btn-profile-build {
    margin-bottom: 20px;
  }
  p.profiletext {
    color: #7e7e7e;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: center;
  }
  a.languges.m-lg-0.langugesheager-profile.nav-link {
    width: 36px;
    height: 36px;
    border: 1px solid rgb(0 0 0 / 5%);
    display: flex;
    align-items: center;
    justify-content: center !important;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-align: left;
    color: hsl(0deg 0% 0% / 50%);
    padding: 0 !important;
    margin: 0 !important;
  }
  a.shareicon {
    width: 36px;
    height: 36px;
    border: 1px solid rgb(0 0 0 / 5%);
    display: flex;
    align-items: center;
    justify-content: center !important;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-align: left;
    color: hsl(0deg 0% 0% / 50%);
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 100%;
    top: 20px;
    right: 15px;
  }
  .profile-tag-info h2 i {
    display: flex;
    align-items: center;
  }
  p.profiletext.ar a {
    transform: translateY(5px);
  }
}
.filtermdal .modal-dialog.modal-dialog-centered {
  height: 70%;
}
@media screen and (max-width: 767px) {
  .filtermdal .modal-dialog.modal-dialog-centered {
    height: 100%;
  }
  .price.checkcategories.ar ul.pricemain.ar {
    height: 90vh;
  }
}
.profile-tag-info.ar h2 i {
  margin-top: 5px;
}
/* profile */
.profilesocialmediamain ul {
  background: transparent !important;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  padding: 0;
  gap: 20px;
}
.profilesocialmediamain {
  background: transparent;
  border: none;
}
.profilesocialmediamain ul li {
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 8px 13px;
  display: flex;
  align-items: center;
}
.profilesocialmediamain i {
  height: 24px;
  line-height: 0;
}
.profile-tag-btn-wrap a {
  height: 56px;
}
a.btn.btn-gray {
  border-color: #ededee !important;
  background: #ededee !important;
}
.profile-tag-info h2 {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.13px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profilesocialmediamain img {
  filter: brightness(0.1);
}
.profilesocialmediamain a {
  width: 100%;
}
.profilesocialmediamain ul li a {
  width: 100%;
  align-items: center;
  height: 32px;
}
.profilesocialmediamain ul li {
  padding: 12px 32px;
}
.profilesocialmediamain span {
  color: #33333b;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
}
.profilesocialmediamain span {
  display: flex;
  align-items: center;
  gap: 9px;
  /* margin-right: 9px; */
}
a.btn.btn-gray.btn-profile-build {
  width: 100%;
  background: #ededee !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  text-align: left;
}
a.btn.btn-gray.btn-profile-build {
  margin-bottom: 20px;
}
p.profiletext {
  color: #7e7e7e;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .profiletext span {
        max-width: 70px;
    } */
.profiletext span img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
a.languges.m-lg-0.langugesheager-profile.nav-link {
  width: 36px;
  height: 36px;
  border: 1px solid rgb(0 0 0 / 5%);
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  color: hsl(0deg 0% 0% / 50%);
  padding: 0 !important;
  margin: 0 !important;
}
.profilesocialmediamain.ar {
  direction: rtl;
}
.profilesocialmediamain.ar span img {
  transform: rotate(180deg);
}
.profilesocialmediamain.ar i + span {
  margin-right: 12px;
  margin-left: auto;
}
a.shareicon {
  width: 36px;
  height: 36px;
  border: 1px solid rgb(0 0 0 / 5%);
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.04em;
  text-align: left;
  color: hsl(0deg 0% 0% / 50%);
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 100%;
  top: 20px;
  right: 15px;
}
.profile-tag-info h2 i {
  display: flex;
  align-items: center;
}
.bussinesworkdetail:hover {
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
}
.me-auto.navbar-nav.navbar-nav-scroll a:hover {
  transition: all 0.5s;
  color: #1b62f5;
}
.languges:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.5s;
}
.btn-gray:hover {
  transition: all 0.5s;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    #ededee;
}
.btn-black:hover {
  color: #fff;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 100%
    ),
    #1d1d26;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
.spinner-box {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.circle-border {
  width: 50px;
  height: 50px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63, 249, 220);
  background: linear-gradient(
    0deg,
    rgb(255 255 255 / 10%) 33%,
    rgb(0 0 0) 100%
  );
  animation: spin 0.8s linear 0s infinite;
}
.circle-core {
  width: 100%;
  height: 100%;
  background-color: #f6f6f7;
  border-radius: 50%;
}
.modal-dialog.modal-lg.modal-dialog-centered {
  max-width: 900px;
}
.languges:hover {
  color: white;
}
.css-1jqq78o-placeholder {
  color: #080921 !important;
}
.me-auto.ar-menu.text-end.navbar-nav.navbar-nav-scroll {
  align-items: center;
  gap: 16px;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  gap: 24px;
}
.selectOverFlow {
  /* overflow-x: scroll; */
  padding: 5px 0 0;
}
.selectOverFlow button.btn.btn-primary {
  min-width: 100px;
}
.selectOverFlow .text-end {
  min-width: 139px;
}
header.profileheader nav {
  padding: 20px 0;
}
.profile-tag-btn-wrap.wp-button a {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: left;
}
a.shareicon {
  width: 44px;
  height: 44px;
}
a.languges.m-lg-0.langugesheager-profile.nav-link {
  width: 44px;
  height: 44px;
}
section.profile-main {
  padding-top: 150px;
}
.profilemainimg {
  margin-bottom: 40px;
}
.profile-tag-info h2 {
  font-family: Roboto;
  font-size: 48px;
  font-weight: 500;
  line-height: 56.25px;
  text-align: left;
}
.profile-tag-info h6 a {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
}
.profile-tag-info {
  margin-bottom: 64px;
}
.profilesocialmediamain span {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: left;
}
.profilesocialmediamain i + span img {
  width: 16px;
  height: 12px;
}
a.btn.btn-gray.btn-profile-build {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  text-align: left;
  /* margin-bottom: 67px; */
}
p.profiletext {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  text-align: left;
}
.Toastify__toast-theme--light {
  background: black;
  border-radius: 10px;
}
.Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
  display: none;
}
.Toastify__progress-bar--wrp {
  opacity: 0;
}
button.Toastify__close-button.Toastify__close-button--light {
  display: none;
  text-align: center;
}
.Toastify__toast-body {
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  text-align: center;
  color: white;
}
.profilesocialmediamain ul li a i + span {
  margin-right: auto;
  margin-left: 13px;
}
.profilesocialmediamain ul li:hover {
  box-shadow: 6px 6px #ededee;
}
.profilesocialmediamain ul li {
  transition: all 0.5s;
}
a.btn.btn-gray.btn-profile-build:hover {
  background: rgb(0 0 0 / 10%) !important;
}
.profile-tag-btn-wrap a {
  font-size: 18px;
}
a.btn.btn-gray:hover {
  background: rgb(0 0 0 / 10%) !important;
}
.profile-tag-info h2 {
  margin-top: 12px;
}
button.btnblue.viewresult:hover {
  background: #113e9d !important;
  color: #fff;
}
button.btnblue.viewresult {
  transition: all 0.5s;
  border: 1px solid #1a62f5;
}
.selectedcity.d-flex ul li {
  flex-direction: row;
  display: flex;
}
ul.pricemain.starslocation {
  width: 50%;
}
.selectedcity.d-flex ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.Toastify__toast-body div {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.Toastify a.shareicon {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.Toastify a.shareicon:hover {
  background-color: #fff !important;
}
.Toastify__toast-container.Toastify__toast-container--bottom-center {
  max-width: 284px;
  width: 100%;
  padding: 0;
}
@media (max-width: 575px) {
  .profile-tag-btn-wrap a {
    font-size: 14px;
    height: 40px !important;
  }
  .Toastify a.shareicon {
    width: 30px;
    height: 30px;
  }
  .accordion {
  }
  section.faqsec.text-end button {
    text-align: right;
  }
  .profilesocialmediamain ul {
    background: transparent !important;
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    gap: 20px;
  }
  .profilesocialmediamain {
    background: transparent;
    border: none;
  }
  .profilesocialmediamain ul li {
    width: 100%;
    background: white;
    border-radius: 10px;
    padding: 8px 13px;
    display: flex;
    align-items: center;
  }
  .profilesocialmediamain i {
    height: 24px;
    line-height: 0;
  }
  .profilesocialmediamain ul li {
    padding: 12px 12px;
  }
  .profile-tag-btn-wrap a {
    height: 56px;
  }
  a.btn.btn-gray {
    border-color: #ededee !important;
    background: #ededee !important;
  }
  .profile-tag-info h2 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.13px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profilesocialmediamain img {
    filter: brightness(0.1);
    margin: 0;
  }
  .profilesocialmediamain a {
    width: 100%;
  }
  .profilesocialmediamain ul li a {
    width: 100%;
    align-items: center;
    height: 24px;
  }
  .profilesocialmediamain span {
    color: #33333b;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-align: left;
  }
  .profilesocialmediamain span {
    display: flex;
    align-items: center;
    gap: 9px;
    /* margin-right: 9px; */
  }
  a.btn.btn-gray.btn-profile-build {
    width: 100%;
    background: #ededee !important;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    text-align: left;
  }
  a.btn.btn-gray.btn-profile-build {
    margin-bottom: 20px;
  }
  p.profiletext {
    color: #7e7e7e;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profiletext span {
    max-width: 70px;
  }
  .profiletext span img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  a.languges.m-lg-0.langugesheager-profile.nav-link {
    width: 36px;
    height: 36px;
    border: 1px solid rgb(0 0 0 / 5%);
    display: flex;
    align-items: center;
    justify-content: center !important;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-align: left;
    color: hsl(0deg 0% 0% / 50%);
    padding: 0 !important;
    margin: 0 !important;
  }
  a.shareicon {
    width: 36px;
    height: 36px;
    border: 1px solid rgb(0 0 0 / 5%);
    display: flex;
    align-items: center;
    justify-content: center !important;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-align: left;
    color: hsl(0deg 0% 0% / 50%);
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 100%;
    top: 20px;
    right: 15px;
  }
  .profile-tag-info h2 i {
    display: flex;
    align-items: center;
  }
  .profiletext span {
    max-width: 100%;
  }
  section.profile-main {
    padding-top: 30px;
  }
  header.profileheader nav {
    padding-top: 0;
  }
  .profilemainimg {
    margin-bottom: 24px;
  }
  .profile-tag-info h6 a {
    font-size: 16px;
  }
  .profile-tag-btn-wrap.wp-button a {
    height: 40px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    text-align: left;
  }
  .profile-tag-info {
    margin-bottom: 40px;
  }
  .profilesocialmediamain span {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-align: left;
  }
  a.btn.btn-gray.btn-profile-build {
    margin-top: 40px;
  }
  p.profiletext span {
    max-width: 76px;
  }
  p.profiletext {
    display: flex;
    align-items: flex-end;
  }
  .profilesocialmediamain ul li:hover {
    box-shadow: 6px 6px #ededee;
  }
  .profilesocialmediamain ul li {
    transition: all 0.5s;
  }
  .Toastify__toast-theme--light {
    padding: 0;
    min-height: 44px;
    font-size: 14px;
  }
  .Toastify__toast-body {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 16px;
  }
  .Toastify__toast-container.Toastify__toast-container--bottom-center {
    max-width: 210px;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    height: 44px;
  }
}
.profilesocialmediamain.ar {
  direction: rtl;
}
.profilesocialmediamain.ar span img {
  transform: rotate(180deg);
}
.profilesocialmediamain.ar i + span {
  margin-right: 12px;
  margin-left: auto;
}
p.profiletext.ar {
  direction: rtl;
}
p.profiletext.ar {
  margin-right: 0;
  gap: 5px;
}
a.shareicon:hover {
  background: rgb(0 0 0 / 10%);
  border-color: rgb(0 0 0 / 10%);
}
a.languges.m-lg-0.langugesheager-profile.nav-link:hover {
  background: rgb(0 0 0 / 10%) !important;
  border: 1px solid rgb(0 0 0 / 10%) !important;
}
p.profiletext.ar a {
  display: block;
  transform: translateY(3px);
}
@media (min-width: 768px) {
  .w-md-50 {
    width: 50% !important;
  }
}
