body {
  /* background: #fff !important; */
}
header.profileheader nav {
  width: 100%;
  max-width: 720px;
}
section.profile-main {
  max-width: 720px;
  margin: 0 auto;
  padding: 100px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-direction: column;
}
.profilemainimg {
  margin: auto;
  margin-bottom: 0 !important;
  width: 348px;
  height: 348px;
  max-width: 348px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 200px;
  }
}
.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  .user-username {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .username {
      display: flex;
      height: 28px;
      padding: 8px;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid rgba(0, 244, 39, 0.3);
      background: rgba(0, 244, 39, 0.05);
      div p {
        color: #080921;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      div:nth-child(2) {
        width: 8px;
        height: 8px;
        border-radius: 10px;
        background: #00f427;
      }
    }
    .user-details {
      display: flex;
      gap: 4px;
      justify-content: center;
      align-items: center;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      div h2 {
        color: #080921;
        font-family: Roboto;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      div i {
        color: #1a62f5;
        svg {
          width: 33px;
          height: 33px;
        }
      }
    }
    .user-url h6 a {
      color: #77777d;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
.profile-tag-info {
  display: flex;
  width: 100%;
  gap: 12px;
  margin-bottom: 0 !important;
  flex-wrap: wrap;
  .social-btns {
    display: flex;
    width: 100%;
    gap: 12px;
  }
  div {
    text-align: center;
    flex: 1 0 0;
    .btn {
      padding: 12px;
      height: 48px;
      border-radius: 8px;
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .btn-gray {
      background: #ededee;
      color: #33333b;
      &:hover {
        color: #1dba58;
      }
    }
    .btn-black2 {
      background: #1d1d26;
      color: #f9f9f9;
    }
  }
}
.profile-social-containers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 16px;
  .social-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: #fff;
    height: 56px;
    padding: 12px;
    .social-item-icon-info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      div p {
        color: #33333b;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
.section {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  gap: 24px;
  width: 100%;
}
.header-title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .title h3 {
    color: #000;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 130% */
    text-transform: capitalize;
  }
  .arrows {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
.overview-row {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Grays-Gray-5, #e5e5ea);
  .about-me,
  .category-me,
  .sub-info {
    h5 {
      color: var(--Labels-secondary, rgba(60, 60, 67, 0.6));
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 111.111% */
    }
    p {
      color: var(--Labels-primary, #080921);
      font-feature-settings: "clig" off, "liga" off;
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
    span {
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      color: var(--Primary-main, #1a62f5);
      font-feature-settings: "clig" off, "liga" off;
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
    }
    .category {
      display: flex;
      height: 24px;
      padding: 10px 11px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 20px;
      border: 1px solid var(--Grays-Gray-5, #e5e5ea);
      .main {
        color: var(--Labels-primary, #080921);
        font-feature-settings: "clig" off, "liga" off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 114.286% */
      }
      p {
        color: var(--Labels-primary, #080921);
        font-feature-settings: "clig" off, "liga" off;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
      }
    }
  }
}
.profile-portfolio {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .portfolio {
    display: grid;
    grid-gap: 20px;
  }
}
.portfolio div:nth-child(1) {
  grid-area: 1 / 1 / 3 / 2;
  .portfolio-img {
    width: 162px;
    max-width: 162px;
    height: 344px;
    position: relative;
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 24px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      );
      opacity: 0.2;
    }
    .company-title-left-container {
      left: 16px;
    }
    .company-title-container {
      position: absolute;
      bottom: 16px;
      z-index: 99;
      .title {
        border-radius: 12px;
        background: #fff;
        backdrop-filter: blur(2px);
        display: inline-flex;
        flex-direction: row-reverse;
        /* height: 36px; */
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 24px;
    }
  }
}
.portfolio div:nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
  .portfolio-img {
    width: 162px;
    max-width: 162px;
    height: 162px;
    border-radius: 24px;
    position: relative;
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 24px;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.2) 100%
        ),
        lightgray 50% / cover no-repeat;
      opacity: 0.2;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 24px;
      border: 1px solid #ebebeb;
    }
  }
}
.portfolio div:nth-child(3) {
  grid-area: 2 / 2 / 3 / 3;
  .portfolio-img {
    width: 162px;
    max-width: 162px;
    height: 162px;
    position: relative;
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 24px;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.2) 100%
        ),
        lightgray 50% / cover no-repeat;
      opacity: 0.2;
    }
    .icon-container {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 32px;
      height: 32px;
      img {
        width: 100%;
        height: 100%;
        border: 0;
        object-fit: cover;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 24px;
      border: 1px solid #ebebeb;
    }
  }
}
.portfolio-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
}
.work-with-items {
  .work-with-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .work-with-item-info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      .image-container {
        width: 72px;
        height: 72px;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .contract-status {
          position: absolute;
          top: 0;
          left: 0;
          height: 32px;
          display: flex;
          height: 16px;
          padding: 2px 6px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 0px 0px 8px 0px;
          background: rgba(255, 255, 255, 0.5);
          backdrop-filter: blur(2px);
          p {
            color: var(--Color, #020814);
            font-feature-settings: "clig" off, "liga" off;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 13px; /* 130% */
          }
        }
      }
      div h4 {
        color: #080921;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .work-with-item-details {
      p {
        color: #77777c;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        span {
          color: #080921;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px; /* 128.571% */
        }
      }
    }
    .work-with-item-date {
      p {
        color: rgba(60, 60, 67, 0.6);
        font-feature-settings: "clig" off, "liga" off;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px; /* 108.333% */
      }
    }
  }
}
.store-item {
  position: relative;
  border-radius: 24px;
  height: 178px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    object-fit: cover;
  }
  .left-btn-container {
    left: 26px;
  }
  .btn-container {
    position: absolute;
    bottom: 26px;
    z-index: 99;
    .btn {
      border-radius: 12px;
      background: #fff;
      backdrop-filter: blur(2px);
      display: inline-flex;
      height: 36px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: #000;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
    }
  }
  .icon-container {
    position: absolute;
    width: 100%;
    right: 16px;
    top: 16px;
    width: 24px;
    height: 24px;
    img {
      width: 100%;
      height: 100%;
      border: 0;
      object-fit: cover;
    }
  }
}
.my-youtube-item {
  .layout {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}
.partner-item {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 12px;
  .partner-img {
    width: 120px;
    height: 120px;
    border-radius: 12px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  .partner-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    gap: 12px;
    .partner-name h5 {
      color: #33333b;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .partner-btn {
      width: 100%;
      .btn {
        display: flex;
        width: 100%;
        height: 40px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: #ededee;
        color: #020814;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    .copy-copon-container {
      display: flex;
      flex-direction: row-reverse;
      gap: 4px;
      align-items: center;
      .copon-info p {
        color: #33333b;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
.product-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .product-img {
    width: 161px;
    height: 161px;
    border-radius: 12px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  .product-name h5 {
    overflow: hidden;
    color: var(--Labels-primary, #080921);
    font-feature-settings: "clig" off, "liga" off;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
  }
  .product-salary p {
    overflow: hidden;
    color: var(--Labels-secondary, rgba(60, 60, 67, 0.6));
    font-feature-settings: "clig" off, "liga" off;
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.4px;
  }
}
.agency-row {
  padding: 12px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  .agency-item {
    display: flex;
    gap: 8px;
    align-items: center;
    .agency-info h4 {
      color: #1d1d26;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
    }
    .agency-details {
      display: flex;
      padding: 8px 12px;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      width: fit-content;
      p {
        color: #33333b;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 100% */
      }
    }
  }
  .info {
    p {
      color: var(--Labels-primary, #080921);
      font-feature-settings: "clig" off, "liga" off;
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
    span {
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      color: var(--Primary-main, #1a62f5);
      font-feature-settings: "clig" off, "liga" off;
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
    }
  }
}
.sperarator {
  width: 100%;
  hr {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}
.aboutme-seperator {
  hr {
    background: #f2f2f7 !important;
  }
}
@media (max-width: 767px) {
  section.profile-main {
    max-width: 343px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .profile-tag-info {
    flex-direction: column-reverse;
  }
  .profilemainimg {
    width: 210px;
    height: 210px;
  }
  .user-details div h2 {
    font-size: 24px;
  }
  .agency-row {
    flex-direction: column;
    align-items: flex-start;
  }
}
