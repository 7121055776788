.downloadnojom h3 {
    font-size: 56px;
    margin-bottom: 48px;

}

.downloadnojom {
    background: #3561FF;
    border-radius: 30px;
    padding: 40px 74px;
    /* background-image: url(../../../../public/Images/nojomappbg.png); */
    background-image: url('/public/Images/nojomappbg.png');
    background-repeat: no-repeat;
}

.forbrand h4 {
    font-size: 32px;
    margin-bottom: 6px;
}

.forbrand {
    margin-bottom: 20px;
}

span.appmodal {
    position: absolute;
    right: 60px;
    top: 50;
    top: 50%;
    transform: translateY(-50%);
}

.downloadnojom {
    position: relative;
}

section.downloadnojomsec {
    padding-top: 150px;
}


.downloadnojom {
    background: #EEF6FF;
}

.downloadnojom h3 {
    color: black;
}

.forbrand h4 {
    color: black;
}
.influencedetail i {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 11px;
}.influencedetail i img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.downloadbtn {
    display: flex;
    gap: 10px;
}

.downloadbtn a {
    display: block;
}