.herobtn .btn-blue:hover {
  background: transparent;
  color: #1a62f5;
}

.herobtn .btn-blue:hover {
  background: transparent;
  color: #1a62f5;
}

a.btn-talent:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

a.btn-talent {
  transition: all 0.5s;
}

.languges {
  transition: all 0.5s;
}

input[type="mail"] {
  color: white;
}

.categorieslist {
  transition: all 0.5s;
}

.categorieslist:hover {
  transform: scale(1.1);
}

.categorieslist.viewall:hover {
  background: #080921;
  color: white;
}

.categorieslist.viewall:hover a {
  color: white;
}

.categorieslist a {
}

.categorieslist.viewall a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

a {
  transition: all 0.5s;
}

ul.footersocial li:hover {
  transform: translateY(-5px);
}

ul.footersocial a {
  position: relative;
}

ul.footersocial li {
  transition: all 0.5s;
}

ul.footermenu a:hover {
  color: #1a62f5;
}

ul.footermenu li {
  transition: all 0.5s;
}

ul.footermenu li:hover {
  transform: translateY(-5px);
}

section.herobanner:after {
  z-index: -9;
}

/* .heroimg {
    position: absolute;
    transform: translateY(-40%);
    right: -96px;
} */

section.herobanner .col {
  position: relative;
}
