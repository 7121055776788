/* .findmoreinfluence h2 {
    font-size: 40px;
    margin: 0;
    color: #080921;
}
.findmoreinfluence {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}
.findmoreinfluence a {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #1A62F5;
}
.findmoreinfluence a svg {
    margin-left: 5px;
} */
/* .findmoreinfluence a {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #1a62f5;
}
.selectfilter {
  display: flex;
  align-items: center;
  gap: 7px;
}
.selectfilter select {
  width: auto;
  background-color: #ffffff;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #f2f2f7;
}
.selectfilter .search-header {
  margin-left: auto;
}
.selectfilter .search-header input {
  height: 36px;
  background-color: #fff;
  margin-right: 0 !important;
} */
/* .influencedetail h3 {
  font-size: 15px;
  color: #080921;
  margin-bottom: 5px;
}
.influencedetail p {
  font-size: 12px;
  color: #aeaeb2;
} */
/* ul.socialmedia {
  display: flex;
  align-items: center;
  gap: 13px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
ul.socialmedia li {
  font-size: 12px;
  color: #8e8e93;
} */
/* .influencedetail h5 {
  font-size: 13px;
  color: #080921;
  font-weight: 500;
}
.influencedetail h5 span {
  color: #8e8e93;
} */
/* .influencedetail {
  padding: 20px;
  display: block;
  background: #ffffff;
  border-radius: 19px;
} */

/* ul.socialmedia li span {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.socialmedia li {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 12px;
  line-height: 14px;
}
.influencedetail p {
  word-wrap: break-word;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.influencedetail {
  height: calc(100% - 25px);
}
.influencedetail i {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 11px;
  overflow: hidden;
}*/
.selectfilter {
  margin-bottom: 40px;
}
.selectfiltermain {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.bussineswormain::-webkit-scrollbar {
  height: 2px;
}
.css-b62m3t-container {
  max-width: 190px;
  width: 100%;
}
.selectfiltermain {
  width: 100%;
  justify-content: flex-start !important;
}
.css-3w2yfm-ValueContainer {
  display: flex;
  flex-wrap: wrap;
  overflow: auto !important;
  height: 34px;
  padding-top: 0;
}
.css-1p3m7a8-multiValue {
  width: 47%;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}
ul.pricemain li {
  display: flex;
  align-items: center;
}
ul.pricemain input {
  margin-left: auto;
  width: 23px;
  height: 23px;
}
ul.pricemain p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #080921;
  margin: 0;
}
ul.pricemain {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.price h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #080921;
  margin-bottom: 32px;
}
.fade.pricemodal.modal .modal-body {
  padding: 24px;
}
.minmax input {
  width: 100% !important;
  background: #f3f4f8;
  height: 50px !important;
  border-radius: 10px;
  border: transparent;
  padding: 13px;
}
.minmax {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 12px;
}
button.btnblue.viewresult {
  width: 100%;
  height: 50px;
  border-radius: 50px;
  background: #1a62f5;
  border: none;
  color: white;
}
button.btnclear.viewresult {
  width: 100%;
  height: 50px;
  border-radius: 50px;
  border: none;
  color: black;
  background: transparent;
}
.minmax input {
  width: 100% !important;
  background: #f3f4f8;
  height: 50px !important;
  border-radius: 10px;
  border: transparent;
  padding: 13px;
}
.minmax {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 12px;
}
button.btnblue.viewresult {
  width: 100%;
  height: 50px;
  border-radius: 50px;
  background: #1a62f5;
  border: none;
  color: white;
}
button.btnclear.viewresult {
  width: 100%;
  height: 50px;
  border-radius: 50px;
  border: none;
  color: black;
  background: transparent;
}
.price input.me-2.form-control {
  width: 100%;
}
.influencedetail h3 span {
  font-family: "Roboto" !important;
}
ul.socialmedia li {
  font-family: "Roboto" !important;
}
.price .search-header {
  margin-bottom: 20px;
}
.selectfiltermain button.btn.btn-primary {
  border-radius: 4rem !important;
  border: 1px solid var(--systemgray-gray6, #f2f2f7) !important;
  background: #fff;
  color: black;
  font-family: "roboto";
}
ul.pricemain label {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}
.filteraccord.accordion .accordion-button {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.filteraccord.accordion .accordion-item {
  padding: 10px 0px;
}
input.me-2.mb-4.form-control {
  width: 100%;
}
.fade.pricemodal.modal .modal-dialog {
  max-width: 600px;
}
.price.mb-0 h4 {
  margin-bottom: 10px;
}
.influencedetail h3 img {
  margin-left: 4px !important;
  display: inline-block;
}
a.influencedetail.text-end h3 img {
  margin-right: 4px !important;
}
button.cancelbtn-outside.btn {
  text-decoration: underline;
  font-weight: bold;
}
span.count-filter {
  background: black;
  color: #ffffff;
  padding: 4px 8px;
  size: 14px;
  line-height: 20px;
  align-items: center;
  font-weight: 500;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}
/* .selectfilter button.btn.btn-primary {
    min-width: 100px;
} */
