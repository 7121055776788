footer {
  background: #080921;
  padding: 64px 104px;
}
.footermail div h4 {
  color: rgba(255, 255, 255, 0.95);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 44.8px */
}
.footermail .mainText {
  color: rgba(255, 255, 255, 0.95);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 32px */
}
.mail input {
  height: 48px;
  width: 100%;
  background: transparent;
  border: 1px solid #8e8e93;
  border-radius: 56px;
  padding: 13px 24px;
  max-width: 547px;
}
.mail form {
  display: flex;
  align-items: center;
  gap: 16px;
}
.mail .btn {
  display: flex;
  height: 48px;
  padding: 20px 48px;
  justify-content: center;
  align-items: center;
  border-radius: 56px;
  border: 2px solid #1a62f5;
  background: #1a62f5;
  color: rgba(255, 255, 255, 0.95);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  letter-spacing: 0.5px;
}
.footermail .subText {
  color: #aeaeb2;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.footermail .subText .link {
  color: rgba(255, 255, 255, 0.95);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.actionContent .actionBtn {
  display: flex;
  gap: 8px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 68px;
  border: 2px solid #aeaeb2;
  color: rgba(255, 255, 255, 0.95);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}
.actionContent .actionText {
  color: rgba(255, 255, 255, 0.95);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 32px */
}
.footerNavigate li a {
  color: rgba(255, 255, 255, 0.95);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}
.copyright p {
  color: #aeaeb2;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
@media (max-width: 767px) {
  .footermail div h4 {
    font-size: 18px;
  }
  .footermail .mainText {
    font-size: 14px;
  }
  .mail form {
    flex-direction: column;
  }
  .mail .btn {
    width: 100%;
  }
  .actionContent .actionText {
    font-size: 20px;
  }
}
