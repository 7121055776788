.title h2 {
  color: #080921;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 44px */
}
.nojom {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.nojom h3 {
  color: #080921;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}
.nojom p {
  color: #8e8e93;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

