/* .me-auto.navbar-nav.navbar-nav-scroll a {
    color: #080921;
    font-size: 16px;
    font-weight: 500;
    margin: 0px 23px 0px 15px;
} */

.contactform {
  max-width: 1024px;
  margin: auto;
  background: white;
  border-radius: 30px;
  padding: 40px;
}

section.contactus {
  padding-top: 150px;
}

.contactformtitle h3 {
  font-size: 40px;
  color: #1a62f5;
  margin-bottom: 10px;
}

.contactformtitle p {
  font-size: 16px;
  color: #080921;
  color: #080921;
}

.contactformtitle {
  margin-bottom: 40px;
}

label.form-label {
  font-size: 16px;
  color: #080921;
  font-weight: normal;
  display: flex;
}

label.form-label {
}

.form-control {
  background: #f6f6f7;
  height: 48px;
  border: none;
  font-size: 15px;
  border-radius: 10px;
}

textarea {
  width: 100%;
  background: #f6f6f7;
  border: none;
  padding: 13px;
  border-radius: 10px;
  font-size: 15px;
  height: 140px;
}

.contactform button {
  width: 100%;
  background: #1a62f5;
  border-radius: 50px;
  height: 48px;
}

ul.contact span {
  width: 48px;
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #1a62f5;
  margin-right: 10px;
}

ul.contact a {
  display: flex;
  align-items: center;
  color: #080921;
  font-size: 20px;
}

ul.contact {
  display: flex;
  flex-flow: column;
  gap: 22px;
  margin-top: 24px;
}

.contactdetail img {
  width: auto !important;
}

.contactform form {
  margin-right: 50px;
}
.contactform input:focus {
  background: #f6f6f7;
}
