section.whynojomsection {
    background: white;
}

.whynojomcontent {
    text-align: center;
    margin-bottom: 40px;

}

.whynojomcontent h2 {
    font-size: 32px;
    color: #1A62F5;
    font-weight: 600;
}

.whynojomcontent p {
    font-size: 16px;
    color: #1A62F5;
}

.ourmission.nojomtitle h3 {
    font-size: 18px;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5px;

}



.ourmission.nojomtitle img {
    display: inline-block;
    margin-bottom: 24px;
}

.ourmission.nojomtitle p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.5px;

}

.ourmission.nojomtitle {
    height: 100%;
}

.startcampaigncontent {
    text-align: center;
}

.startcampaigncontent h2 {
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.5px;
    text-align: center;
    color: black;
    margin-bottom: 40px;
}

.startcampaigncontent p {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-align: center;
    text-align: center;
    color: black;
    margin-bottom: 40px;
}

.startcampaigncontent a {
    max-width: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    color: white;
    height: 96px;
    background: #1A62F5;
    border-radius: 50px;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
}

.startcampaigncontent svg {
    margin-left: 10px;
}