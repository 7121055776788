.mainTitle {
  color: #080921;
  text-align: center;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 85.714% */
  letter-spacing: 0.5px;
}
.mainTitle span {
  color: #1a62f5;
}
.mainText {
  color: #080921;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 38.4px */
  letter-spacing: 0.5px;
}
.mainText b {
  color: #080921;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.5px;
}
.mainLink {
  color: #080921;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
  letter-spacing: 0.5px;
}
.mainLink a {
  color: #1a62f5;
  text-decoration-line: underline;
}
